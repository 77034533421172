/*
* 업무구분: 일정 추가
* 화면 명: MSPAP303P
* 화면 설명: 일정 추가
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" type="subpage" :show-title="true" :title="popupTitle" @on-header-left-click="cancel">
  <!-- <ur-page-container class="msp" :show-title="true" title="일정추가" type="subpage" :topButton="false"> -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">

      <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area">
        <!-- 구분 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <span class="title must mb10 fwm fs18rem">구분</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="schlTab" @click="fn_ChangeSchdlTab">
              <mo-segment-button value="3">공유일정</mo-segment-button>
              <mo-segment-button value="2">개인일정</mo-segment-button>
            </mo-segment-wrapper>
            <span class="mt5 full fs14rem crTy-bk7" v-if="schlTab === '3'" >공유일정은 등록 후 수정이 불가능합니다.<br/>수정이 필요한 경우, 삭제 후 재등록 해주세요.</span> <!-- 공유일정일때-->
          </ur-box-container>
        </ur-box-container>
        <!-- 구분 end -->
        <!-- 일정제목 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <span class="title must mb10 fwm fs18rem">일정제목</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-text-field v-model="schlTitleData" placeholder="입력하세요 (최대 16자)" maxlength="16" underline class="sch-input no-sch mt0"/>
          </ur-box-container>
        </ur-box-container>
        <!-- 일정제목 end -->
        <!-- 대상 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box" v-if="schlTab === '3'"> <!-- 공유일정일때-->
          <span class="title must mb10 fwm fs18rem">대상</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <msp-bottom-select :items="hofOrgItemList" itemHeight="374" :itemValue="'key'" itemText="label" v-model="hofOrgData.key" @input="fn_GetDof" :disabled="lv_OrgAuth.isEntpwRolYn==='N'" class="ns-dropdown-sheet" underline bottom-title="지역 선택" closeBtn scrolling placeholder="전사" />
            <msp-bottom-select :items="dofOrgItemList" itemHeight="374" :itemValue="'key'" :itemText="'label'" v-model="dofOrgData.key" @input="fn_GetFof" :disabled="lv_OrgAuth.isHofRolYn==='N'" class="ns-dropdown-sheet mt20" underline bottom-title="지역단 선택" closeBtn scrolling placeholder="전체" />
            <msp-bottom-select :items="fofOrgItemList" itemHeight="374" :itemValue="'key'" :itemText="'label'" v-model="fofOrgData.key" :disabled="lv_OrgAuth.isDofRolYn==='N'"  class="ns-dropdown-sheet mt20" underline bottom-title="지점 선택" closeBtn scrolling placeholder="전체" />
            <!-- <msp-bottom-select :items="fofLst" v-model="slctFofData" class="ns-dropdown-sheet mt20" underline bottom-title="지점 선택" closeBtn scrolling placeholder="강남금융지점"/> -->
          </ur-box-container>
        </ur-box-container>
        <!-- 대상 end -->
        <!-- 일시 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <div class="fexTy3">
            <span class="title must">일시</span>
            <span class="crTy-bk7">하루종일<mo-switch v-model="toggle" small class="ns-switch ml7"/></span>
          </div>
          <div class="add-date">
            <mo-date-picker v-model="startYmd" placeholder="선택일" class="ns-date-picker" />
            <span class="ns-space-10"></span>
            <mo-time-picker v-if="!toggle" v-model="fcSchdlStrHm"  class="ns-time-picker" />
          </div>
          <div class="add-date mt20">
            <mo-date-picker bottom v-model="endYmd" placeholder="선택일" class="ns-date-picker" />
            <span class="ns-space-10"></span>
            <mo-time-picker v-if="!toggle" v-model="fcSchdlEndHm"  class="ns-time-picker" />
          </div>
        </ur-box-container>
        <!-- 일시 end -->
        <!-- 장소 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box">
          <span class="title mb10 fwm fs18rem">장소</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-text-field v-model="placeData" placeholder="입력하세요 (최대 33자)" underline class="sch-input no-sch mt0"/>
          </ur-box-container>
        </ur-box-container>
        <!-- 장소 end -->
        <!-- 분류 -->
        <ur-box-container alignV="start" componentid="" direction="column" class="add-box"  v-if="schlTab === '3'"> <!-- 공유일정일때-->
          <span class="title must">분류</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="mngrCustCntcData" class="chip-type-wrap chip-ty1">
              <!-- <mo-segment-button value="3">콜로세움</mo-segment-button>
              <mo-segment-button value="4">JOB설명회</mo-segment-button>
              <mo-segment-button value="5">VIP세미나</mo-segment-button>
              <mo-segment-button value="6">교육</mo-segment-button>
              <mo-segment-button value="7">조회</mo-segment-button>
              <mo-segment-button value="8">기타</mo-segment-button> -->
              <template v-for="(data, i) in mngrCustCntcList" >
                <mo-segment-button :key="i" :value="data.id">{{ data.name }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 분류 end -->
        <!-- 내용 -->
        <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="add-box"  v-if="schlTab === '3'"> <!-- 공유일정일때 -->
          <span class="title">내용</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-text-area maxlength="50" v-model="fcSchdlCntnt" class="hauto pa12" rows="5" placeholder="입력하세요 (최대 50자)" />
          </ur-box-container>
        </ur-box-container>
        <!-- 내용 end -->
        <!-- 휴대폰알림 -->
        <ur-box-container alignV="start" componentid="ur_box_container_015" direction="column" class="add-box mb50">
          <span class="title">휴대폰알림</span>
            <div class="alarm-box">
              <mo-checkbox v-model="isPushYn" class="ns-check">사용</mo-checkbox>
              <template v-if="isPushYn">
                <msp-bottom-select :items="mobileAlarmList" :itemValue="'value'" :itemText="'text'" v-model="fcIapTmScCdsKeys" underline class="ns-dropdown-sheet" bottom-title="휴대폰알림" closeBtn scrolling placeholder="선택" />
                <mo-time-picker v-model="fcIapHm" class="ns-time-picker" />
              </template>
            </div>
            <template v-if="isPushYn && schlTab === '3'">
              <div class="text-gray-box mt20">
                <ul class="terms-list-area crTy-bk7 fs14rem">
                  <li>소속된 지역단 또는 지점의 사랑On미니 설치자에게 푸시알림이 갑니다.</li>
                  <li>휴대폰알림 설정 시, 일정을 삭제해도 알림발송 취소가 불가합니다.</li>
                </ul>
              </div>
            </template>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column"  class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="cancel">취소</mo-button>
            <mo-button v-if="validationYn" componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" disabled>확인</mo-button>
            <mo-button v-else componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click.stop="fn_validation">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import MspBottomSelect from '@/ui/sa/MSPSA203P_BottomSelect'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import apConstants from '@/config/constants/apConstants'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP303P",
    screenId: "MSPAP303P",
    components: {
      MspBottomSelect
    },
    props: {
      pChoiceYmd: {
        type: String,
        default: ''
      },
      pFcSchdlSno: {
        type: Number,
        default: 0
      },
      pMobslChnlCustId: {
        type: String,
        default: ' '
      },
      pCustAlctnCmpgnId: {
        type: String,
        default: ''
      },
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_OnLoad()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      this.fn_GetFofRol()
      this.fcIapHm = this.fcSchdlStrHm
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
        hofOrgData: {key: '00000000', label: '전사'}, // 선택사업부
        dofOrgData: {key: '0', label: '전체'}, // 선택지역단
        fofOrgData: {key: '0', label: '전체'}, // 선택지점
        //lv_FofCnslt: {key: '0', label: '전체'}, // 선택컨설턴트
        hofOrgItemList: [], // 사업부 목록
        dofOrgItemList: [], // 지역단 목록
        fofOrgItemList: [], // 지점 목록
        //lv_FofCnsltData: [], // 컨설턴트 목록
        lv_OrgAuth: {}, // 지점권한
        isFirst: 0, // 첫 조회 기준
        isInValid: false, // 밸리데이션용
        inValidMsg: '', // 밸리데이션 체크시 메세지
        popupTitle: '',
        dofLst: [], // 지역단선택
        fofLst: [], // 지점 선택
        toggle: false, // 종일여부
        startYmd: '', // 일정 시작일시
        endYmd: '', // 일정 종료일시
        reqFirst: false, // 중복 호출 방지를 위해
        fcSchdlStrHm: this.fn_SetTime(moment(new Date()).add(1, 'hour').format('hh:00 A')), // 일정 시작 시간
        fcSchdlEndHm: this.fn_SetTime(moment(new Date()).add(2, 'hour').format('hh:00 A')), // 일정 종료 시간
        mngrCustCntcList: [], // 분류 목록
        mngrCustCntcData: '', // 분류 선택
        schlTitleData:'', // 일정 제목
        isPushYn: false, // 휴대폰알림 여부
        schlTab: '3', // 구분 탭
        fcIapTmScCdsKeys: '1', // 휴대폰알람 사용시 알람시점
        fcIapHm: ' ', // 휴대폰알람 사용시 알람시간
        placeData: ' ', // 장소
        validationYn: true, // 확인버튼 활성화 여부
        fcSchdlCntnt: ' ', // 공유 내용 데이터
        schdlInfoVO: { // I/F(fn_InsSchdlInfo)로 보낼 정보 모음.
          fcSchdlSno: 0,
          fcSchdlScCd: ' ', // FC일정 일련번호
          mobslChnlCustId: ' ', // 모바일 영업채널고객ID
          fcSchdlNm: ' ',
          fcSchdlStrYmd: ' ',
          fcSchdlStrHm: ' ',
          fcSchdlEndYmd: ' ',
          fcSchdlEndHm: ' ',
          fcSchdlPrmsPlcNm: ' ',
          custAlctnCmpgnId: ' ', // 고객배정캠페인ID
          cntcObjCmpgnNm: ' ',
          fcIapReciYn: ' ',
          fcIapPushReqId: 0,
          fcIapReqId: 0,
          fcIapTmScCd: ' ',
          fcIapHm: ' ',
          custCntcId: ' ',
          fcSchdlCntnt: ' '
        },
      }
    },
    watch: {
      toggle() {
        if (!this.toggle) {
          let t_startTime = moment(new Date()).add(1, 'hour').format('hh:00 A')
          let t_endTime = moment(new Date()).add(2, 'hour').format('hh:00 A')
          this.fcSchdlStrHm = this.fn_SetTime(t_startTime)
          this.fcSchdlEndHm = this.fn_SetTime(t_endTime)
        } else {
          this.fcSchdlStrHm = ''
          this.fcSchdlEndHm = ''
        }
      },
      startYmd() {
        console.log('일정 시작일자 변경')
        console.log('비교', Number(this.startYmd.replaceAll('-', '')) > Number(this.endYmd.replaceAll('-', '')))
        if (Number(this.startYmd.replaceAll('-', '')) > Number(this.endYmd.replaceAll('-', ''))) {
          this.endYmd = this.startYmd
          console.log('this.endYmd>>', this.endYmd)
        }
      },
      endYmd() {
        console.log('일정 종료일자 변경')
        if (Number(this.startYmd.replaceAll('-', '')) > Number(this.endYmd.replaceAll('-', ''))) {
          this.startYmd = this.endYmd
          console.log('this.startYmd>>', this.startYmd)
        }
      },
      fcSchdlStrHm() {
        if ((this.startYmd === this.endYmd) && Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlStrHm)) > Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlEndHm))) {
          let t_strHm = Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlStrHm))
          if (t_strHm === 2300) {
            this.endYmd = moment(this.endYmd).add(1, 'days').format('YYYY-MM-DD')
            this.fcSchdlEndHm = this.fn_UITimeCompr('0000')
          } else {
            this.fcSchdlEndHm = this.fn_UITimeCompr(String(t_strHm + 100))
          }
        }
      },
      fcSchdlEndHm() {
        console.log('일정 종료시간 변경',this.fcSchdlEndHm)
        if ((this.startYmd === this.endYmd) && Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlStrHm)) > Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlEndHm))) {
          let t_endHm = Number(this.fn_ServerTimeComprfcIapHm(this.fcSchdlEndHm))
          if (t_endHm === 0) {
            console.log('this.startYmd>>', this.startYmd)
            this.startYmd = moment(this.startYmd).subtract(1, 'days').format('YYYY-MM-DD')
            this.fcSchdlStrHm = this.fn_UITimeCompr('2300')
          } else {
            console.log('this.t_endHm>>', t_endHm )
            this.fcSchdlStrHm = this.fn_UITimeCompr(String(t_endHm - 100))
          }
        }
      },
      schlTitleData() {
        if (this.schlTitleData.length > 0) {
          this.validationYn = false
        } else {
          this.validationYn = true
        }
      }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      mobileAlarmList() {
        let rtn = [];
        rtn.push({
          value: '1',
          text: '당일'
        });
        rtn.push({
          value: '2',
          text: '1일전'
        });
        return rtn;
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*********************************************************
       * Function명: fn_OnLoad
       * 설명: 최초 데이터 셋팅
       *********************************************************/
      fn_OnLoad() {
        this.popupTitle = this.pFcSchdlSno > 0 ? '일정수정': '일정추가'
        console.log('this.pFcSchdlSno>>>', this.pFcSchdlSno , this.popupTitle)
        if (Number(this.pFcSchdlSno) > 0) { // 일정수정
          this.fn_SearchSchedule()
        } else { // 일정추가
          this.startYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD')
          this.endYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD')
          this.mngrCustCntcList = apConstants.mngrCustCntc
          this.mngrCustCntcData = apConstants.mngrCustCntc[0].id
        }
      },
      /*********************************************************
       * Function명: fn_SearchSchedule
       * 설명: 수정일 경우 데이터 조회
       *********************************************************/
      fn_SearchSchedule () {
        let lv_Vm = this
        let trnstId = 'txTSSAP20S1'
        let pParam = {
          userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
          fcSchdlSno: Number(lv_Vm.pFcSchdlSno)
        }
        lv_Vm.post(lv_Vm, pParam, trnstId, 'S')
          .then(function (response) {
            console.log('fn_SearchSchedule response>><><><', response)
            if ( response.body != null) {
              console.log('mobslChnlCustId ' + response.body.mobslChnlCustId )
              // 공유일정은 수정불가하여 무조건 개인일정
              lv_Vm.schlTab = response.body.fcSchdlScCd // 구분탭
              lv_Vm.schlTitleData= response.body.fcSchdlNm // 일정제목
              lv_Vm.startYmd = response.body.fcSchdlStrYmd // 시작일시
              lv_Vm.endYmd = response.body.fcSchdlEndYmd // 종료일시
              lv_Vm.placeData = response.body.fcSchdlPrmsPlcNm // 장소
              
              if ( response.body.fcSchdlStrHm != null && response.body.fcSchdlEndHm != null ) {
                lv_Vm.fcSchdlStrHm = lv_Vm.fn_UITimeCompr(response.body.fcSchdlStrHm)
                lv_Vm.fcSchdlEndHm = lv_Vm.fn_UITimeCompr(response.body.fcSchdlEndHm)
              } else {
                lv_Vm.toggle = true
              }

              if ( response.body.fcIapReciYn === 'Y' ) {
                lv_Vm.isPushYn = true
                lv_Vm.fcIapTmScCdsKeys = response.body.fcIapTmScCd
                lv_Vm.fcIapHm = lv_Vm.fn_UITimeCompr(response.body.fcIapHm)
              } else {
                lv_Vm.isPushYn = false
              } 
            }
          })
      },
      /*********************************************************
       * Function명: fn_UITimeCompr
       * 설명: 서버에서 받아온 데이터 포맷팅
       *********************************************************/
      fn_UITimeCompr (time) {
        console.log('fn_UITimeCompr', time)
        if (time.length === 4) {
          let timeHm = time.substring(0, 2)
          let timeMm = time.substring(2, 4)
          if (Number(timeHm) === 0) {
            timeHm = '12'
            time = timeHm + ':' + timeMm + ' ' + 'AM'
          } else if (Number(timeHm) === 12) {
            timeHm = '12'
            time = timeHm + ':' + timeMm + ' ' + 'PM'
          } else {
            time = timeHm + ':' + timeMm
          }
          console.log('수정일시 response data  formating>>', time)
          return this.fn_SetTime( moment(time, 'h:mm A').format('hh:mm A'))
        }
      },
      /*********************************************************
       * Function명: fn_SetTime
       * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
       *********************************************************/
      fn_SetTime (time) {
        let timeA = time.substring(6, 8)
        let timeHH = time.substring(0, 2)
        let timeMM = time.substring(3, 5)
        if (timeA.trim() === 'AM') {
          timeA = '오전'
        } else {
          timeA = '오후'
        }
        let t_time = timeA + ' ' + timeHH + ':' + timeMM
        return t_time
      },
      /******************************************************************************
      * Function명 : fn_GetDof
      * 설명       : 지역단 재조회 ( SearchOrg 참고 )
      ******************************************************************************/
      fn_GetDof ( param ) {
        console.log('fn_GetDof >> ', param)
        if ( param ) {
          console.log('선택한 키>>>', this.hofOrgData.key)
          console.log('선택한 라벨>>>', this.hofOrgData.label)
          this.lv_SearchParam.hofOrgNo = this.hofOrgData.key
          if (this.dofOrgItemList) { this.dofOrgItemList.slice(0) }
          if (this.fofOrgItemList) { this.fofOrgItemList.slice(0) }
          // if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.dofOrgData = {key: '0', label: '전체'} // 지역단
          this.fofOrgData = {key: '0', label: '전체'} // 지점
          // this.lv_FofCnslt = {key: '0', label: '전체'}
          if (this.hofOrgData.key !== '0' && this.hofOrgItemList.length > 1 ) {
            console.log('this.lv_SearchParam>>>>>', this.lv_SearchParam)
            this.$bizUtil.selListDofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack) // 지역단 리스트 재조회
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_GetFof
      * 설명       : 지점 재조회
      ******************************************************************************/
      fn_GetFof (param) {
        console.log('fn_GetFof param>> >> >> ', param)
        if ( param ) {
          this.lv_SearchParam.hofOrgNo = this.hofOrgData.key
          this.lv_SearchParam.dofOrgNo = this.dofOrgData.key
          if (this.fofOrgItemList) { this.fofOrgItemList.slice(0) }
          // if (this.lv_FofCnsltData) { this.lv_FofCnsltData.slice(0) }
          this.fofOrgData = {key: '0', label: '전체'}
          // this.lv_FofCnslt = {key: '0', label: '전체'}
        }
        console.log('지점 재ㅎ조ㅎ회ㅎ>>', this.hofOrgData , this.dofOrgData )
        this.$bizUtil.selListFofOrg(this.lv_SearchParam, this.fn_OrgSearchCallBack)
      },
      /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_OrgSearchCallBack ( rtnData, sType ) {
        console.log('fn_OrgSearchCallBack1 >>> ', rtnData)
        console.log('fn_OrgSearchCallBack2 >>> ', sType)

        this.hofOrgItemList = rtnData[0] // 사업부
        this.dofOrgItemList = rtnData[1] // 지역단
        this.fofOrgItemList = rtnData[2] // 지점
        if (this.isFirst === 0) {
          let isSelHof = false
          for ( let oData of rtnData[0] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo ) {
              this.hofOrgData = oData
              isSelHof = true
              break
            }
          }
          if (!isSelHof) this.hofOrgData = {key: '00000000', label: '전사'} // 사업부 초기화

          let isSelDof = false
          for ( let oData of rtnData[1] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstDofNo ) {
              this.dofOrgData = oData
              isSelDof = true
              break
            }
          }
          if (!isSelDof) this.dofOrgData = {key: '0', label: '전체'} // 지역단 초기화

          let isSelFof = false
          for ( let oData of rtnData[2] ) {
            if ( oData.key === this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo ) {
              this.fofOrgData = oData
              isSelFof = true
              break
            }
          }
          if (!isSelFof) this.fofOrgData = {key: '0', label: '전체'} // 지점 초기화
        }
        this.isFirst++
      },
      /******************************************************************************
      * Function명 : fn_GetFofRol
      * 설명       : 사용자의 조직조회 권한 확인
      ******************************************************************************/
      fn_GetFofRol () {
        console.log('fn_GetFofRol >> ')
        this.isFirst = 0
        this.$bizUtil.selFofRol({}, this.fn_GetFofRolCallBack )
      },
      /******************************************************************************
      * Function명 : fn_GetFofRolCallBack
      * 설명       : 시스템 옵션 조회
      * 인자       : BCOptnMngSrchISVO.userEno 사용자사번
      * {isEntpwRolYn[전사역할여부]=Y/N,
      * isHofRolYn[사업부역할여부]=Y/N,
      * isDofRolYn[지역단역할여부]=Y/N,
      * isEofRolYn[지점역할여부]=Y/N}
      * Return     : BCOptnMngSrchOSVO
      ******************************************************************************/
      fn_GetFofRolCallBack ( pData ) {
        console.log('fn_GetFofRolCallBack >> ', pData)
        if ( pData ) {
          this.lv_OrgAuth = pData
          let sOrgAuth = pData.isEntpwRolYn + pData.isHofRolYn + pData.isDofRolYn + pData.isEofRolYn
          console.log( '-------------------' )
          console.log( sOrgAuth )
          console.log( 'HOF::' + this.getStore('userInfo').getters.getUserInfo.onpstHofOrgNo )
          console.log( 'DOF::' + this.getStore('userInfo').getters.getUserInfo.onpstDofNo )
          console.log( 'FOF::' + this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo )
          console.log( this.getStore('userInfo').getters.getUserInfo )
          console.log( '-------------------' )
          // lv_SearchParam: { whlInqrYn: 'Y', hofOrgNo: '', dofOrgNo: '', fofOrgNo: '', orgNo: '', cnsltInqrYn: '' }, // 검색조건
          this.lv_SearchParam.fofOrgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          this.lv_SearchParam.orgNo = this.getStore('userInfo').getters.getUserInfo.onpstFofOrgNo
          this.$bizUtil.selUserOrgList(this.lv_SearchParam, this.fn_OrgSearchCallBack)
        }
      },
      /******************************************************************************
      * Function명 : fn_ChangeSchdlTab
      * 설명       : 공유일정/개인일정 변경 시 화면 초기화
      ******************************************************************************/
      fn_ChangeSchdlTab () {
        this.fn_ClearData()
      },
       /*********************************************************
       * Function명: fn_validation
       * 설명: 확인 버튼 클릭시 밸리데이션 체크
       *********************************************************/
        fn_validation() {
          if(this.reqFirst) return
          this.reqFirst = true
          // 밸리데이션 체크
          if (this.fn_ValidateBeforeSubmit()) {
            console.log('밸리데이션!!!')
            this.fn_BottomAlert(this.inValidMsg)
          } else {
            if (this.schlTab === '3') { // 공유일정
              if (this.isPushYn) {
                let content = '휴대폰 알림 설정시, 일정을 삭제해도 알림발송 취소가 불가합니다.<br/> 그래도 설정하시겠습니까?'
                this.fn_BottomConfirm(content)
              } else {
                this.fn_InsSchdlInfo()
              }
            } else { // 개인일정
              this.fn_InsSchdlInfo()
            }
          }
        },
        /*********************************************************
         * Function명: fn_InsSchdlInfo
         * 설명: 고객 정보 저장 메서드
         *********************************************************/
        fn_InsSchdlInfo() {
          let lv_Vm = this
          let trnstId = ''
          console.log('fn_InsSchdlInfo실행!!!!>>>', lv_Vm.pFcSchdlSno)
          if (Number(lv_Vm.pFcSchdlSno) > 0) {
            trnstId = 'txTSSAP20U1'
            lv_Vm.schdlInfoVO.fcSchdlSno = lv_Vm.pFcSchdlSno
          } else {
            trnstId = 'txTSSAP20I1'
          }
          console.log('trnstId>>>', trnstId)
          lv_Vm.schdlInfoVO.fcSchdlScCd = lv_Vm.schlTab // 공유/개인 구분
          lv_Vm.schdlInfoVO.fcSchdlNm = lv_Vm.schlTitleData // 일정명
          lv_Vm.schdlInfoVO.fcSchdlStrYmd = lv_Vm.startYmd // 일정 시작일
          lv_Vm.schdlInfoVO.fcSchdlEndYmd = lv_Vm.endYmd // 일정 종료일
          if ( this.toggle ) { // 종일여부
            lv_Vm.schdlInfoVO.fcSchdlStrHm = ' ' // 일정 시작시간
            lv_Vm.schdlInfoVO.fcSchdlEndHm = ' ' // 일정 종료시간
          } else {
            lv_Vm.schdlInfoVO.fcSchdlStrHm = this.fn_ServerTimeCompr(lv_Vm.fcSchdlStrHm)
            lv_Vm.schdlInfoVO.fcSchdlEndHm = this.fn_ServerTimeCompr(lv_Vm.fcSchdlEndHm)
          }
          if (lv_Vm.schlTab === '3') { // 공유일시
            lv_Vm.schdlInfoVO.custCntcId = lv_Vm.mngrCustCntcData // 분류
            lv_Vm.schdlInfoVO.fcSchdlCntnt = lv_Vm.fcSchdlCntnt // 내용
            //lv_Vm.schdlInfoVO.fcSchdlCntnt = lv_Vm.fcSchdlCntnt !== '' ? lv_Vm.fcSchdlCntnt : ' ' // 내용
          } 
          if (this.isPushYn) { // 알람사용여부
            lv_Vm.schdlInfoVO.fcIapReciYn = 'Y'
            lv_Vm.schdlInfoVO.fcIapTmScCd = this.fcIapTmScCdsKeys
            lv_Vm.schdlInfoVO.fcIapHm = this.fn_ServerTimeComprfcIapHm(lv_Vm.fcIapHm)
          } else {
            lv_Vm.schdlInfoVO.fcIapReciYn = 'N'
            lv_Vm.schdlInfoVO.fcIapTmScCd = ' '
            lv_Vm.schdlInfoVO.fcIapHm = ' '
          }
          
          let pParam = {
            userEno: this.getStore('userInfo').getters.getUserInfo.userId,
            fcSchdlSno: lv_Vm.schdlInfoVO.fcSchdlSno,
            fcSchdlScCd: lv_Vm.schdlInfoVO.fcSchdlScCd,
            mobslChnlCustId: lv_Vm.schdlInfoVO.mobslChnlCustId,
            fcSchdlNm: lv_Vm.schdlInfoVO.fcSchdlNm,
            fcSchdlStrYmd: lv_Vm.schdlInfoVO.fcSchdlStrYmd.substring(0, 10).replace(/-/gi, ''),
            fcSchdlStrHm: lv_Vm.schdlInfoVO.fcSchdlStrHm,
            fcSchdlEndYmd: lv_Vm.schdlInfoVO.fcSchdlEndYmd.substring(0, 10).replace(/-/gi, ''),
            fcSchdlEndHm: lv_Vm.schdlInfoVO.fcSchdlEndHm,
            fcSchdlPrmsPlcNm: lv_Vm.placeData,
            custAlctnCmpgnId: lv_Vm.schdlInfoVO.custAlctnCmpgnId,
            cntcObjCmpgnNm: lv_Vm.schdlInfoVO.cntcObjCmpgnNm,
            fcIapReciYn: lv_Vm.schdlInfoVO.fcIapReciYn,
            fcIapTmScCd: lv_Vm.schdlInfoVO.fcIapTmScCd,
            fcIapPushReqId: lv_Vm.schdlInfoVO.fcIapPushReqId,
            fcIapReqId: lv_Vm.schdlInfoVO.fcIapReqId,
            fcIapHm: lv_Vm.schdlInfoVO.fcIapHm,
            custCntcId: lv_Vm.schdlInfoVO.custCntcId,
            fcSchdlCntnt: lv_Vm.schdlInfoVO.fcSchdlCntnt,
            shareHofOrgNo: lv_Vm.hofOrgData.key,
            shareDofOrgNo: lv_Vm.dofOrgData.key,
            shareFofOrgNo: lv_Vm.fofOrgData.key
          }
          console.log('lv_Vm.pParam>>', pParam)
          lv_Vm.post(lv_Vm, pParam, trnstId, 'I')
            .then(function (response) {
              if (lv_Vm.DEBUG) console.log(response)
              if (response.msgComm.msgCd === 'ECOU002' || response.msgComm.msgCd === 'ECOU003' ) {
                if (lv_Vm.schdlInfoVO.fcSchdlScCd === '3') { // 공유인 경우
                  //lv_Vm.fn_BottomAlert('공유일정이 추가되었습니다.') 
                  lv_Vm.getStore('toast').dispatch('ADD', '공유일정이 추가되었습니다.')
                } else { // 개인인 경우
                  if (trnstId === 'txTSSAP20U1') {
                    lv_Vm.getStore('toast').dispatch('ADD', '개인일정이 수정되었습니다.')
                  } else {
                    lv_Vm.getStore('toast').dispatch('ADD', '개인일정이 추가되었습니다.')
                  }
                }
                lv_Vm.$emit('fn_OnRefresh') // fn_OnRefresh
                lv_Vm.fn_CloseScreen()
              } else {
                if (response.msgComm.msgCd === 'ECOU008' || response.msgComm.msgCd === 'EFWS004') {
                  lv_Vm.fn_BottomAlert('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                } else {
                  lv_Vm.fn_BottomAlert('저장 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
                }
              }
              lv_Vm.reqFirst = false
            }).catch(function (error) {
              window.vue.error(error)
              lv_Vm.reqFirst = false
            })
        },
        /*********************************************************
         * Function명: fn_ServerTimeCompr
         * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
         * Params: N/A
         * Return: N/A
         *********************************************************/
        fn_ServerTimeCompr (time) {
          let timeA, timeHm, timeMm
            timeA = time.substring(0, 2)
            timeHm = time.substring(3, 5)
            timeMm = time.substring(6, 8)
          if (timeA === '오전') {
            if (Number(timeHm) === 12) {
              time = '00' + timeMm
            } else {
              time = timeHm + timeMm
            }
          } else {
            if (Number(timeHm) === 12) {
              time = timeHm + timeMm
            } else {
              time = Number(timeHm) + 12 + timeMm
            }
          }
          console.log('fn_ServerTimeCompr', time)
          return time
        },
        /*********************************************************
         * Function명: fn_ServerTimeComprfcIapHm
         * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
         * Params: N/A
         * Return: N/A
         *********************************************************/
        fn_ServerTimeComprfcIapHm (time) {
          let timeA, timeB, timeHm, timeMm
            timeA = time.substring(5, 8)
            timeB = time.substring(0, 2)
            if (timeA === '오전' || timeA === '오후') {
              timeHm = time.substring(0, 2)
              timeMm = time.substring(3, 5)
              if (timeA === '오전') {
                if (Number(timeHm) === 12) {
                  time = '00' + timeMm
                } else {
                  time = timeHm + timeMm
                }
              } else {
                if (Number(timeHm) === 12) {
                  time = timeHm + timeMm
                } else {
                  time = Number(timeHm) + 12 + timeMm
                }
              }
            } else {
              timeHm = time.substring(3, 5)
              timeMm = time.substring(6, 8)
              if (timeB === '오전') {
                if (Number(timeHm) === 12) {
                  time = '00' + timeMm
                } else {
                  time = timeHm + timeMm
                }
              } else {
                if (Number(timeHm) === 12) {
                  time = timeHm + timeMm
                } else {
                  time = Number(timeHm) + 12 + timeMm
                }
              }
            }
          console.log('fn_ServerTimeComprfcIapHm', time)
          return time
        },
      /******************************************************************************
        * Function명 : fn_BottomAlert
        * 설명       : 알림 팝업 호출
        ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: contents,
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            }
          }
        })
      },
      /******************************************************************************
        * Function명 : fn_BottomConfirm
        * 설명       : 확인/취소 팝업 호출
        ******************************************************************************/
      fn_BottomConfirm (contents) {
        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '',
          content: contents,
          title_neg_btn:'아니오',
          title_pos_btn: '예'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_InsSchdlInfo()
            this.$bottomModal.close(this.confirmPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.confirmPop);
            }
          }
        })
      },
      /******************************************************************************
      * Function명 : fn_CloseScreen
      * 설명       : 취소버튼 선택 시 호출  - 디비전 닫는 이벤트 emit
      ******************************************************************************/
      fn_CloseScreen () { 
        this.fn_ClearData()
      },
      /******************************************************************************
      * Function명 : fn_ClearData
      * 설명       : 초기화
      ******************************************************************************/
      fn_ClearData() {
        this.toggle = false // 종일여부
        this.startYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD') // 일정시작일
        this.endYmd = moment(this.pChoiceYmd).format('YYYY-MM-DD') // 일정종료일
        this.fcSchdlStrHm = this.fn_SetTime(moment(new Date()).add(1, 'hour').format('hh:00 A')) // 일정시작시간
        this.fcSchdlEndHm = this.fn_SetTime(moment(new Date()).add(2, 'hour').format('hh:00 A')) // 일정종료시간
        this.schlTitleData = '' // 일정명
        this.isFirst = 0 // 첫조회
        this.fn_GetFofRol() // 대상
        this.placeData = ''     // 장소
        this.mngrCustCntcData = apConstants.mngrCustCntc[0].id  // 분류
        this.fcSchdlCntnt = '' // 내용
        this.isPushYn = false  // 알림 여부
        this.fcIapTmScCdsKeys = '1' //알림 시점
        this.fcIapHm = this.fn_SetTime(moment(new Date()).add(1, 'hour').format('hh:00 A')) // 알림 시간
      },
      /*********************************************************
       * Function명: fn_ValidateBeforeSubmit
       * 설명: 고객 정보 수정 전 Validation 함수
       * Return: isInValid ( 오류 여부 true/false)
       *********************************************************/
        fn_ValidateBeforeSubmit () {
          this.isInValid = false
          this.inValidMsg = ''
          if ( !this.toggle ) { //  종일X
            let strTime = this.fcSchdlStrHm.trim()
            let endTime = this.fcSchdlEndHm.trim()
            strTime = this.fn_ServerTimeCompr(this.fcSchdlStrHm)
            endTime = this.fn_ServerTimeCompr(this.fcSchdlEndHm)
            let strHm, strMm, endHm, endMm
            if (strTime.length < 8) {
              strHm = strTime.substring(0, 2)
              strMm = strTime.substring(2, 4)
            } else if (strTime.length >7) {
                strHm = strTime.substring(0, 2)
                strMm = strTime.substring(2, 4)
            } 
            if (endTime.length < 8) {
              endHm = endTime.substring(0, 2)
              endMm = endTime.substring(2, 4)
            } else if (endTime.length > 7) {
                endHm = endTime.substring(0, 2)
                endMm = endTime.substring(2, 4)
            }
            if ( strHm === '' || strMm === '' ) {
              this.isInValid = true
              this.inValidMsg = '시작일시을 입력하세요.'
              return this.isInValid
            } else if( endHm === '' || endMm === '' ) {
              this.isInValid = true
              this.inValidMsg = '종료일시을 입력하세요.'
              return this.isInValid
            }
            if ( Number(this.startYmd.substring(0, 10).replace(/-/gi, '')) > Number(this.endYmd.substring(0, 10).replace(/-/gi, '')) ) {
              this.isInValid = true
              this.inValidMsg = '종료일자를 시작일자\n 이후로 설정해주세요.'
              return this.isInValid
            }
            if ( this.startYmd.substring(0, 10) === this.endYmd.substring(0, 10) ) {
              if ( Number(strHm) > Number(endHm)) {
                this.isInValid = true
                this.inValidMsg = '종료일시를 시작일시\n 이후로 설정해주세요.'
                return this.isInValid
              }
            }
          } else {
            if ( Number(this.startYmd.substring(0, 10).replace(/-/gi, '')) > Number(this.endYmd.substring(0, 10).replace(/-/gi, '')) ) {
              this.isInValid = true
              this.inValidMsg = '종료일자를 시작일자\n 이후로 설정해주세요.'
              return this.isInValid
            }
          }
          // 휴대폰알림에 대한 체크
          if ( this.isPushYn ) {
            let time, timeA, timeHm, timeMm, strA, strHm, strMm
            console.log(this.fcIapHm)
            timeA = this.fcIapHm.substring(0, 2)
            timeHm = this.fcIapHm.substring(3, 5)
            timeMm = this.fcIapHm.substring(6, 8)
            console.log('timeHm', timeHm)
            console.log('timeMm', timeMm)
            console.log('timeA', timeA)
            strA = this.fcSchdlStrHm.substring(6, 8)
            strHm = this.fcSchdlStrHm.substring(3, 5)
            strMm = this.fcSchdlStrHm.substring(0, 2)
            time = this.fn_ServerTimeComprfcIapHm(this.fcIapHm)
            if ( timeA === '' || timeHm === '' || timeMm === '' ) {
              this.isInValid = true
              this.inValidMsg = '휴대폰 알림시각을 입력해주세요.'
              return this.isInValid
            }
            let nowDt = this.$bizUtil.getDateToString(new Date(), '') + this.$bizUtil.getTimeToString(new Date(), '').substring(0, 4)
            let iapDt = this.startYmd.substring(0, 10).replace(/-/gi, '') + time
            console.log('확인11', nowDt , '[]', iapDt)
            if ( Number(nowDt) > Number(iapDt) ) {
              this.isInValid = true
              this.inValidMsg = '현재 시간 이전으로\n 휴대폰알림을 등록할 수 없습니다.'
              return this.isInValid
            }
            let currentDate = this.$bizUtil.getDateToString(new Date(), '')
            let startDate = this.startYmd.substring(0, 10).replace(/-/gi, '')
            console.log('확인22', currentDate , '[]', startDate)
            if ( Number(startDate) === Number(currentDate) && this.fcIapTmScCdsKeys === '2') {
              this.isInValid = true
              this.inValidMsg = '현재 일자 이전으로\n 휴대폰알림을 등록할 수 없습니다.'
              return this.isInValid
            }
          }
          return this.isInValid
        },
      cancel() {
        this.$emit('onPopupClose')
      }
    }
  };
</script>
<style scoped>
</style>