<template>
  <div class="mo-dropdown mo-input msp-bottom-select" :class="dropdownClass">
    <div
      class="mo-dropdown__title"
      :class="dropdownTitleClass"
      @touchstart="onTouchStart"
      @click="onClick"
    >
      <!--$ver: v0.0.1
            $hide
        --><slot name="selected" :item="selItem" :getText="getText">
        <div
          class="mo-dropdown__selected"
          :class="selectedClass"
          :title="getText(selItem)"
        >
          <!--
                        $ver: v0.0.1
                        드랍다운 항목 내부에 위치하는 영역 입니다.
                        #This area is located inside the dropdown item.
                    --><slot name="item-text" :item="selItem" :getText="getText"
            ><span>{{ getText(selItem) }}</span></slot
          >
        </div>
      </slot>
      <mo-icon-wrapper class="mo-dropdown__title-icon">
        <mo-icon
          class="mo-dropdown__icon mo-dropdown__icon__warning"
          v-if="errorMessage"
          icon-size="16px"
          picto
          >warning</mo-icon
        >
        <mo-icon
          class="mo-dropdown__icon"
          icon="expand_menu"
          size="16px"
          :style="caretStyle"
        />
      </mo-icon-wrapper>
    </div>
    <mo-bottom-sheet ref="floating" @closed="onClosed" class="msp-bottom-select__bottom-sheet" :close-btn="closeBtn">
      <template #title>
        <mo-icon v-show="hierarchy.length" class="msp-bottom-select__back-icon" picto @click="goToParentItems(selItem)">previous</mo-icon>
        <span class="msp-bottom-select__title">{{bottomTitle}}</span>
      </template>
      <div class="mo-dropdown__items" :style="itemsStyle" :class="itemsClass">
        <div
          class="mo-dropdown__item"
          v-for="(item, idx) in dItems"
          @click.stop="onClickBottomItem(item)"
          :key="idx"
          :class="{ 'mo-dropdown__selected': isSelectedItem(item) }">
          <div class="mo-dropdown__label">
            <span class="line-break">
              <!--                            $hide-->
              <slot name="item-text" :item="item" :getText="getText">{{
                getText(item)
              }}</slot></span>
            <mo-icon-wrapper
              v-if="item.childItems"
              style="width:16px;margin-left:12px;">
              <mo-icon class="mo-dropdown__label-caret" size="10px">expand_menu</mo-icon>
            </mo-icon-wrapper>
          </div>
        </div>
      </div>
    </mo-bottom-sheet>
    <div
      v-if="messageShow"
      class="mo-dropdown__message mo-validator__message"
      :class="messageClass"
      :style="$_messageStyle()">
      <!--glue
        -->{{ messageShow
      }}<!--glue
    --></div>
  </div>
</template>

<script>
import moDropdown from "uidev-compo-mobile/components/element/moDropdown/moDropdown.vue";

export default {
  name: "msp-bottom-select",
  extends: moDropdown,
  props:{
    bottomTitle:{
      type:String,
      default:''
    },
    closeBtn:{
      type:Boolean,
      default:false
    },
    scrolling:{
      type:Boolean,
      default:false
    },
  },
  data() {
    return {
      dItems: this.items,
      hierarchy: [],
    };
  },
  computed: {
    hasChildItems() {
      let flag = false;
      this.dItems.forEach((item) => {
        flag = flag || !!item.childItems;
      });
      return flag;
    },
  },
  watch:{
    items(newVal, oldVal){
      this.dItems = newVal;
    }
  },
  methods: {
    onClosed() {
      this.toggleItemOuter();
      setTimeout(() => {
        this.dItems = this.items;
      }, 500);
      this.hierarchy = [];
    },
    resize(e) {},
    goToParentItems(item){
      const depth = this.hierarchy.length-1;
      if(depth > 0){
        this.dItems = this.hierarchy[depth-1].childItems;
        this.hierarchy.pop();
      } else {
        this.dItems = this.items;
        this.hierarchy = [];
      }
    },
    onClickBottomItem(item) {
      if(item.childItems && item.childItems.length > 0) {
        this.hierarchy.push(item);
        this.dItems = item.childItems;
      } else {
        this.clickItem(item);
      }
    },
  },
};
</script>

<style lang="scss">
.msp-bottom-select {
  .mo-dropdown__items {
    position: unset;
    border: 0px;
    background: transparent;
    max-height: unset;
  }
  .mo-dropdown__item {
    background: transparent;
  }
  .msp-bottom-select__back-icon{
    margin-right: 10px;
  }
}


.mo-modal .msp-bottom-select {
  z-index: 201;
}
</style>
