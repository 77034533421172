/*
* 업무구분: 일정 상세
* 화면 명: MSPAP304P
* 화면 설명: 일정 상세
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" :show-title="true" title="공유일정" type="subpage" :topButton="false" @on-header-left-click="fn_close">
    
    <!-- 전체체크 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="" direction="column" class="con-area bd-b-Ty3">
        <div class="mt20 mb20 fexTy2">
          <span class="fwb fs19rem flex-1 mr10">{{ showSchdlDtl.title }}</span>
          <span class="badge-type location fs12 fwn mt5 pa10">{{ showSchdlDtl.CustCntcNm }}</span>
        </div>
      </ur-box-container>
    </template>

    <!-- 스크롤 List -->
    <ur-box-container direction="column" alignV="start" class="msp-ap-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-list-area">      
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area">
          <div v-if="isManager" class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-group" class="mr20 mt3" />
            <span class="fs17rem flex-1">
              <span>{{ showSchdlDtl.shareHofOrgNoNm !== null ? showSchdlDtl.shareHofOrgNoNm: '전사' }}</span><em class="pl4 pr4">–</em>
              <span>{{ showSchdlDtl.shareDofOrgNoNm !== null ? showSchdlDtl.shareDofOrgNoNm: '전체' }}</span><em class="pl4 pr4">–</em>
              <span>{{ showSchdlDtl.shareFofOrgNoNm !== null ? showSchdlDtl.shareFofOrgNoNm: '전체' }}</span>
            </span>
          </div>
          <!--시간 영역-->
          <div class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-watch" class="mr20 mt3" />
            <div class="flex-1">
              <!--종일-->
              <span v-if="showSchdlDtl.startTime === null || showSchdlDtl.endTime === null" class="fs17rem">
                <span>하루종일</span>
              </span>
              <span v-else class="fs17rem fexTy5">
                <span class="txt-center">{{ showSchdlDtl.startDate }}<br/> {{ showSchdlDtl.startPrd }}</span>
                <mo-icon icon="msp-arrow-line" class="ml30 mr30" />
                <span class="txt-center">{{ showSchdlDtl.endDate }}<br/>{{ showSchdlDtl.endPrd }}</span>
              </span>
            </div>
          </div>
          <!--위치 영역-->
          <div v-if="showSchdlDtl.place" class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-location" class="mr20 mt3" /> 
            <span class="fs17rem flex-1">
              <span>{{ showSchdlDtl.place }}</span>
            </span>
          </div>
          <!--내용 영역-->
          <div v-if="showSchdlDtl.fcSchdlCntnt" class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-doc" class="mr20 mt3" />
            <span class="fs17rem flex-1">
              <span>{{ showSchdlDtl.fcSchdlCntnt }}</span>
            </span>
          </div>
          <!--푸쉬영역-->
          <div class="pt20 pb20 bd-b-Ty3 full fexTy2">
            <mo-icon icon="msp-bell" class="mr20 mt3" /> 
            <span v-if="showSchdlDtl.fcIapTmScCd !== ' '" class="fs17rem flex-1">
              <span>
                {{ fcIapDay }}
                {{ fcIapHm }}
              </span>
            </span>
            <span v-else class="fs17rem flex-1">
              <span>사용안함</span>
            </span>
          </div>


        </ur-box-container>
      </ur-box-container>

    </ur-box-container>

    <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column"  class="ns-btn-relative">
      <div class="relative-div">
        <mo-button v-if="isManager" componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_alertOpen" :disabled="isDelBtnYn">삭제</mo-button>
        <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_close">확인</mo-button>
      </div>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP304P",
    screenId: "MSPAP304P",
    components: {
    },
    props: {
      pschdlDtl: {
        type: Object,
        default: {}
      },
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.showSchdlDtl = this.pschdlDtl
      if (this.getStore('userInfo').getters.getUserInfo.isSrchOrgCombo === 'Y') {
        this.isManager = true
      } else {
        this.isManager = false
      }
      console.log('관리자 여부!!>>', this.isManager)
      console.log('공유일정상세 정보>>>>>', this.showSchdlDtl)
      this.fn_OnLoad()
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      isShow: false,
      isManager: false, // 관리자 여부
      isDelBtnYn: false, // 삭제 가능 여부
      showSchdlDtl: {},
      fcIapDay: '', // 알림일
      fcIapHm : '', // 알림시간
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_OnLoad() {
        let t_peroid = this.showSchdlDtl.periodTime.split('-')
        console.log('기간 자르기!!>>', t_peroid)
        if (t_peroid.length > 1) {
          this.showSchdlDtl.startPrd = t_peroid[0]
          this.showSchdlDtl.endPrd = t_peroid[1]
        }
        if (this.showSchdlDtl.fcIapTmScCd === '1') {
          this.fcIapDay = this.showSchdlDtl.startDate
        } else if (this.showSchdlDtl.fcIapTmScCd === '2') {
          let tmp = this.showSchdlDtl.startDate.replaceAll('-', '')
          this.fcIapDay = moment(tmp).add(-1, 'd').format('YYYY-MM-DD')
        }
        this.fcIapHm = this.fn_ConvertTime(this.showSchdlDtl.fcIapHm)
        console.log('알림일 & 시간>>', this.fcIapDay, this.fcIapHm)
      },
      /*********************************************************
       * Function명: fn_ConvertTime
       * 설명: 시간데이터 한글화
       *********************************************************/
      fn_ConvertTime ( data ) {
        console.log('fn_ConvertTime data>>>', data)
        var ret_val = ''
        if ( data === null || data.length !== 4 ) {
          ret_val = ''
        } else {
          if ( Number( data.substring(0, 2)) < 12 ) {
            ret_val = '오전 ' + data.substring(0, 2) + ':' + data.substring(2, 4)
          } else if ( Number( data.substring(0, 2)) > 12 ) {
            let hh = ( Number(data.substring(0, 2)) - 12 )
            hh = ( hh > 9 ? hh : '0' + hh )
            ret_val = '오후 ' + hh + ':' + data.substring(2, 4)
          } else {
            ret_val = '오후 ' + '12' + ':' + data.substring(2, 4)
          }
        }
        return ret_val
      },
      /*********************************************************
       * Function명: fn_alertOpen
       * 설명: 삭제버튼 클릭시 알럿 오픈
       *********************************************************/
      fn_alertOpen() {
        if (this.showSchdlDtl.fcIapTmScCd !== ' ') {
          let t_fcIap = moment(`${this.fcIapDay} ${this.showSchdlDtl.fcIapHm.substring(0, 2)}:${this.showSchdlDtl.fcIapHm.substring(2, 4)}`).format('YYYY-MM-DD HH:mm')
          let t_today = moment(new Date()).format('YYYY-MM-DD HH:mm')
          let diff = moment.duration(moment(t_fcIap).diff(t_today)).asMinutes()
          console.log('알람시간 확인!!>>', t_fcIap)
          console.log('현재시간 확인!!>>', t_today)
          console.log('분 비교!!>>', diff )
          if (Number(diff) <= 10) {
            this.fn_BottomAlert('알림시간 10분전부터 삭제가 불가능합니다.')
            return
          }
        }
        this.fn_BottomConfirm('일정을 삭제하시겠습니까?')
      },
      /*********************************************************
       * Function명: fn_BottomConfirm
       * 설명: 버튼 2개 알럿 오픈
       *********************************************************/
      fn_BottomConfirm (contents) {
        this.confirmPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '',
          content: contents,
          title_neg_btn:'취소',
          title_pos_btn: '삭제'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_DeleteProcess()
            this.$bottomModal.close(this.confirmPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.confirmPop);
            }
          }
        })
      },

      /******************************************************************************
        * Function명 : fn_BottomAlert
        * 설명       : 싱글 버튼 알림 팝업 호출
        ******************************************************************************/
      fn_BottomAlert (contents) {
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: contents,
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            }
          }
        })
      },

      /*********************************************************
         * Function명: fn_ServerTimeComprfcIapHm
         * 설명: 최초 시작시간 설정 (현재시간보다 + 1)
         *********************************************************/
        fn_ServerTimeComprfcIapHm (time) {
          let timeA, timeB, timeHm, timeMm
            timeA = time.substring(5, 8)
            timeB = time.substring(0, 2)
            if (timeA === '오전' || timeA === '오후') {
              timeHm = time.substring(0, 2)
              timeMm = time.substring(3, 5)
              if (timeA === '오전') {
                if (Number(timeHm) === 12) {
                  time = '00' + timeMm
                } else {
                  time = timeHm + timeMm
                }
              } else {
                if (Number(timeHm) === 12) {
                  time = timeHm + timeMm
                } else {
                  time = Number(timeHm) + 12 + timeMm
                }
              }
            } else {
              timeHm = time.substring(3, 5)
              timeMm = time.substring(6, 8)
              if (timeB === '오전') {
                if (Number(timeHm) === 12) {
                  time = '00' + timeMm
                } else {
                  time = timeHm + timeMm
                }
              } else {
                if (Number(timeHm) === 12) {
                  time = timeHm + timeMm
                } else {
                  time = Number(timeHm) + 12 + timeMm
                }
              }
            }
          console.log('fn_ServerTimeComprfcIapHm', time)
          return time
        },

      /*********************************************************
       * Function명: fn_DeleteProcess
       * 설명: 삭제처리 프로세스
       *********************************************************/
      fn_DeleteProcess () {
          var lv_Vm = this
          var trnstId = 'txTSSAP20D2'
          var pParam = {
            userEno: lv_Vm.getStore('userInfo').getters.getUserInfo.userId,
            fcSchdlSno: Number(lv_Vm.showSchdlDtl.fcSchdlSno),
            fcSchdlScCd: lv_Vm.showSchdlDtl.type,
            fcSchdlStrYmd: lv_Vm.showSchdlDtl.startDate.replaceAll('-', ''),
            fcSchdlEndYmd: lv_Vm.showSchdlDtl.endDate.replaceAll('-', ''),
            fcSchdlStrHm: lv_Vm.showSchdlDtl.startTime === null ? ' ' : lv_Vm.showSchdlDtl.startTime,
            fcSchdlEndHm: lv_Vm.showSchdlDtl.endTime === null ? ' ' : lv_Vm.showSchdlDtl.endTime,
            fcSchdlNm: lv_Vm.showSchdlDtl.title,
          }
          lv_Vm.post(lv_Vm, pParam, trnstId, 'D')
            .then(function (response) {
              if (response.msgComm.msgCd === 'ECOU004') {
                lv_Vm.getStore('toast').dispatch('ADD', '삭제되었습니다.')
                lv_Vm.fn_close()
              } else {
                console.log('삭제 시 오류가 발생하였습니다. \n코드: [' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc )
              }
            })
      },
      fn_close() {
        this.$emit('fn_OnRefresh')
      }
    }
  };
</script>
<style scoped>
</style>